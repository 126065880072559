import './App.scss';
import './Careers.scss';
import { React, useEffect } from 'react';

import { ThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';

import { theme } from './ThemeOverrides';
import { ScrollToTop } from './Common';

import JobPost from './JobPost';
import IconTitleBlockList from './IconTitleBlock';

function Careers(props) {
  const benefits = [
    {
      image: "/images/careers/compensation.png",
      image_alt: "compensation",
      title: "Market Leader Compensation",
      content: "Matching the market is not good enough. When we find the best talent, we want to make it an easy decision to join. We support this by maintaining an average salary at or above the 75th percentile of the national average.",
    },
    {
      image: "/images/careers/bonus.png",
      image_alt: "bonus",
      title: "Performance Bonuses",
      content: "Expectations that are developed jointly with your leadership when you start. When you exceed expectations, you're rewarded for it.",
    },
    {
      image: "/images/careers/401k_match.png",
      image_alt: "401k match",
      title: "Above Market 401k Match",
      content: "Dollar for dollar up to 8% of your base salary.",
    },
    {
      image: "/images/careers/benefits.png",
      image_alt: "employer-paid insurance",
      title: "Employer-Paid Insurance",
      content: "We cover 100% of the monthly premium for Medical, Dental, Vision, Short & Long Term Disability, and Life Insurance.",
    },
    {
      image: "/images/careers/professional_development.png",
      image_alt: "professional development",
      title: "Professional Development",
      content: "Hiring the best doesn't mean much if you don't maintain that edge. All CCG Team Members receive $2,500 per year to pursue certifications, conferences, and courses to improve themselves.",
    },
  ];

  useEffect(() => {
    /* paycor
    if (document.getElementById("gnewtonjs") === null) {
        const paycorScript = document.createElement("script");
        paycorScript.id = "gnewtonjs";
        paycorScript.type = "text/javascript";
        paycorScript.src = "//recruitingbypaycor.com/career/iframe.action?clientId=8a7883d07c5da337017cdd3fe17a7c24";

        const jobListContainer = document.getElementById("job-list-container");
        jobListContainer.appendChild(paycorScript);
    }
    */
    /*
    if (document.getElementById("greenhousejs") === null) {
        const greenhouseScript = document.createElement("script");
        greenhouseScript.id = "greenhousejs";
        greenhouseScript.type = "text/javascript";

        const jobListContainer = document.getElementById("job-list-container");
        jobListContainer.appendChild(greenhouseScript);

        greenhouseScript.onload = () => {
            const loader = document.createElement("script");
            loader.type = "text/javascript";
            loader.innerHTML += "Grnhse.Iframe.load();";

            jobListContainer.appendChild(loader);
        };

        greenhouseScript.src = "https://boards.greenhouse.io/embed/job_board/js?for=chameleonconsultinggroup";
    }
    */
  }, []);

  const renderJobsListing = (jobsListing) => {
    if (!jobsListing.hasOwnProperty('jobs') || !jobsListing['jobs'].length) {
      return (
        <TableBody><TableRow><TableCell>
          There are no open job requisitions at this time.
        </TableCell></TableRow></TableBody>
      )
    }

    var jobPosts = jobsListing['jobs'].map((jobData) =>
      <JobPost
        key={jobData['id']}
        id={jobData['id']}
        title={jobData['title']}
        locationState={jobData['location']['name']}
        displayUrl={jobData['absolute_url']}
      />
    );

    /*
    jobPosts = [ <JobPost
        key={jobsListing['jobs'][0]['id']}
        id={jobsListing['jobs'][0]['id']}
        title={jobsListing['jobs'][0]['title']}
        locationState={jobsListing['jobs'][0]['location']['name']}
        displayUrl={jobsListing['jobs'][0]['absolute_url']}
      />,
       <JobPost
        key={jobsListing['jobs'][1]['id']}
        id={jobsListing['jobs'][1]['id']}
        title={jobsListing['jobs'][1]['title']}
        locationState={jobsListing['jobs'][1]['location']['name']}
        displayUrl={jobsListing['jobs'][1]['absolute_url']}
      />
    ];
    */

    return <TableBody>{jobPosts}</TableBody>
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="scale-left-container">
        <img className="scale-left-img" alt="scale_left" src="/images/scale_left_white.png" />
      </div>
      <div className="scale-right-container">
        <img className="scale-right-img" alt="scale_right" src="/images/scale_right_white.png" />
      </div>
      <section className="u-clearfix u-section-5">
        <ScrollToTop />
        <div className="u-clearfix u-sheet u-sheet-1 careers-sheet">
          <div className="u-container-layout u-container-layout-1">
            <h2 className="align-center">Stand Out by Blending In</h2>
          </div>

          <div className="u-clearfix u-expanded-width u-gutter-30 u-layout-wrap u-layout-wrap-1 icon-layout-wrap">
<p>
Do you want to be surrounded by other high performers in an environment that fosters high expectations, accountability, and humility? Whether you’re a computer engineer looking to push the boundaries of what is technically possible or a former military HUMINTER that’s spent years perfecting physical and/or digital tradecraft, if you’re the best, you belong at CCG.
</p>


<p>
From the outside looking in, you may think a tech startup collided with a Special Operations unit and formed CCG. Developers with no degree to Research Scientists with PhD’s as well as former active duty servicemen and women and civilians call CCG home and share two key traits; an unapologetic passion for the mission and the ability to solve the most difficult problems. At CCG you will find a welcoming team that pushes and supports you to be your best every single day. 
</p>

 
<p>
As a company we strive to lead the conversation by only hiring high performers. Check out some of the ways  we reward your hard work and commitment to excellence:
</p>
          </div>

          <div className="gradient-horizontal-rule" />

          <h2 className="align-center">CCG Perks</h2>

          <IconTitleBlockList className="careers-icon-title-block-container" blocks={benefits} />

          <div className="gradient-horizontal-rule" />

          <h2 id="job_listing" className="align-center">Current Opportunities</h2>

          <p className="u-align-center">
            Ready to work with the best? Apply below, or feel free to reach out to us at <a href="mailto:contact@chameleoncg.com" target="_blank" rel="noreferrer" className="link-text">contact@chameleoncg.com</a>
          </p>

          <div id="grnhse_app"></div>

          <div id="job-list-container" className="job-listing-container">
            <TableContainer>
              <Table aria-label="jobs table">
                {renderJobsListing(props.jobsListing)}
              </Table>
            </TableContainer>
          </div>
      </div>
      </section>

    </ThemeProvider>
  );
}

export default Careers

import './Common.scss';
import './About.scss';
import React from 'react';
import { Link } from 'react-router-dom';

import { ScrollToTop  } from './Common';

function About(props) {

  return (
    <div>
    <div className="scale-left-container">
      <img className="scale-left-img" alt="scale_left" src="/images/scale_left_white.png" />
    </div>
    <div className="scale-right-container">
      <img className="scale-right-img" alt="scale_right" src="/images/scale_right_white.png" />
    </div>
    <section className="u-clearfix u-section-5">
      <ScrollToTop />
      <div className="u-clearfix u-sheet u-sheet-1 about-sheet">
        <h2 className="align-center">We Are Chameleon Consulting Group</h2>
        <p>
        Chameleon Consulting Group, LLC was founded in 2018 by a small group of professionals with extensive experience in cyberspace operations and information operations across the DoD and IC. Our foremost focus is on our customers and their mission success. Our goal is to be a trusted partner and "go-to" provider of critical mission capabilities and expertise for our customers. Our growing team consists of professionals who are experts in their trade and passionate about their work.
        </p>
        <p>
        CCG is built by our employees and we aim to provide them a rewarding and fun work environment, opportunities for professional development and personal development, exciting and meaningful work, and a corporate culture that is their own. Read on to learn more about our vision, mission, and values. Feel free to <a href="mailto:contact@chameleoncg.com" target="_blank" rel="noreferrer" className="link-text">reach out to us</a> if you’re interested in learning more.
        </p>

        <h4><span className="understate">OUR</span> VISION</h4>
Establish CCG as a vanguard<sup>1</sup> in the information warfare environment and to be a trusted and invaluable mission partner to our customers.
<br/>
<br/>
<span><sup>1</sup> <i>Vanguard</i>: a group of people leading the way in new developments or ideas.</span>

        <h4><span className="understate">OUR</span> MISSION</h4>
Provide cutting-edge and innovative solutions to our partners across the full spectrum of technical and physical Cyberspace Operations and Information Operations. We accomplish this via:

          <ul>
            <li>Technical and operational expertise</li>
            <li>Proven ability to deliver highly relevant capabilities, mission expertise, and integrated solutions</li>
            <li>Passion and enduring focus on advancing the capabilities of our nation’s warfighters</li>
          </ul>

        <h4><span className="understate">OUR</span> VALUES</h4>
          <ul>
            <li>Focus on people and their priorities; this applies to team members and customers</li>
            <li>Honesty, Integrity, Respect and Humility are required; no exceptions</li>
            <li>Be at the forefront of new ideas; lead the conversation</li>
            <li>Team members are our differentiator; humans over hardware</li>
            <li>Demand excellence from yourself and team members; hold yourself accountable</li>
            <li>Have fun or GO HOME</li>
          </ul>

See yourself on the Team? Click <Link to="/careers" className="link-text">here</Link> for opportunities to join.
      </div>

      <div className="u-sheet u-sheet-1 about-separator gradient-horizontal-rule" />

      <div className="u-clearfix u-sheet u-sheet-1 about-sheet">
        <h3 className="align-center">CCG Locations</h3>
        <div className="u-layout-row location-row">
          <div className="u-container-style u-layout-cell location-item">
            <div className="u-container-layout u-valign-top u-container-layout-2">
              <p>
                <span className="location-state">Virginia</span><br/>
                505 Huntmar Park Dr.<br/>
                Suite 160<br/>
                Herndon, VA 20170<br/>
                (703) 817-2044
              </p>
            </div>
          </div>
          <div className="u-container-style u-layout-cell location-item">
            <div className="u-container-layout u-valign-top u-container-layout-3">
              <p>
                <span className="location-state">North Carolina</span><br/>
                1744 Heritage Center Dr.<br/>
                Suite 102<br/>
                Wake Forest, NC 27587<br/>
                (703) 817-2044<br/>
              </p>
            </div>
          </div>
        </div>
        <p className="u-align-center">
          For general inquiries please contact us at: <a href="mailto:contact@chameleoncg.com" target="_blank" rel="noreferrer" className="link-text">contact@chameleoncg.com</a>
        </p>
      </div>
    </section>
    </div>
  );
}

export default About
